<template>
    <div ref="root">
        <slot />
    </div>
</template>
<script>
const defer = function defer() {
    let state = false; // Resolved or not

    const callbacks = [];

    const resolve = function resolve() {
        if (state) {
            return;
        }

        state = true;

        for (let i = 0, len = callbacks.length; i < len; i++) {
            callbacks[i]();
        }
    };

    const then = function then(cb) {
        if (!state) {
            callbacks.push(cb);
            return;
        }

        cb();
    };

    return {
        resolved: function resolved() {
            return state;
        },
        resolve: resolve,
        promise: {
            then: then
        }
    };
};

const ownProp = Object.prototype.hasOwnProperty;
function createRecaptcha() {
    const deferred = defer();
    return {
        notify: function notify() {
            deferred.resolve();
        },
        wait: function wait() {
            return deferred.promise;
        },
        render: function render(ele, options, cb) {
            this.wait().then(function() {
                cb(window.grecaptcha.render(ele, options));
            });
        },
        reset: function reset(widgetId) {
            if (typeof widgetId === 'undefined') {
                return;
            }

            this.assertLoaded();
            this.wait().then(function() {
                return window.grecaptcha.reset(widgetId);
            });
        },
        execute: function execute(widgetId) {
            if (typeof widgetId === 'undefined') {
                return;
            }

            this.assertLoaded();
            this.wait().then(function() {
                return window.grecaptcha.execute(widgetId);
            });
        },
        checkRecaptchaLoad: function checkRecaptchaLoad() {
            if (ownProp.call(window, 'grecaptcha') && ownProp.call(window.grecaptcha, 'render')) {
                this.notify();
            }
        },
        assertLoaded: function assertLoaded() {
            if (!deferred.resolved()) {
                throw new Error('ReCAPTCHA has not been loaded');
            }
        }
    };
}
const recaptcha = createRecaptcha();

if (typeof window !== 'undefined') {
    window.vueRecaptchaApiLoaded = recaptcha.notify;
}

export default {
    name: 'VueRecaptcha',
    props: {
        sitekey: {
            type: String,
            default: process.env.VUE_APP_RECAPTCHA_STIE_KEY
        },
        theme: {
            type: String
        },
        badge: {
            type: String
        },
        size: {
            type: String
        },
        tabindex: {
            type: String
        },
        loadRecaptchaScript: {
            type: Boolean,
            default: true
        },
        recaptchaScriptId: {
            type: String,
            default: '__RECAPTCHA_SCRIPT'
        },
        recaptchaHost: {
            type: String,
            default: 'www.google.com'
        },
        language: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            root: null,
            widgetId: null,
        };
    },
    mounted() {
        this.root = this.$refs.root;

        recaptcha.checkRecaptchaLoad();

        if (this.loadRecaptchaScript) {
            if (!document.getElementById(this.recaptchaScriptId)) {
                const script = document.createElement('script');
                script.id = this.recaptchaScriptId;
                script.src = 'https://' + this.recaptchaHost + '/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=' + this.language;
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);
            }
        }

        const opts = Object.assign({}, this.$props, {
            callback: this.emitVerify,
            'expired-callback': this.emitExpired,
            'error-callback': this.emitError
        });

        const $root = this.root;

        const container = this.$slots.default ? $root.children[0] : $root;

        recaptcha.render(container, opts, (id) => {
            this.widgetId = id;
            this.$emit('render', id);
        });
    },
    methods: {
        emitVerify(response) {
            this.$emit('verify', response);
        },
        emitExpired() {
            this.$emit('expired');
        },
        emitError() {
            this.$emit('error');
        },
        reset() {
            recaptcha.reset(this.widgetId);
        },
        execute() {
            recaptcha.execute(this.widgetId);
        }
    },
};
</script>
